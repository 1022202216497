*,
*:after,
*:before {
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
	font-size: 62.5%;
}

body {
  display:flex;
  color: $fg-color;
  background-color: $bg-color;
	font-family: $text-font-family;
	font-size: 1.6em;
	font-weight: 300;
  line-height: 1.8em;
  @media only screen and (max-device-width : 768px) {
    font-size: 1.6em;
    line-height: 1.6em;
  }
}

a {
  font-weight: 300;
  color: $link-color;
  text-decoration: none;
  &:focus,
  &:hover {
    text-decoration: underline;
  }
}

p {
  margin: 2.0rem 0 2.0rem 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-weight: 700;
  color: $alt-fg-color;
  margin: 6.4rem 0 3.2rem 0;
}

h1 {
  font-size: 3.2rem;
  line-height: 3.6rem;
  @media only screen and (max-device-width : 768px) {
    font-size: 3.0rem;
    line-height: 3.4rem;
  }
}
h2 {
  font-size: 2.8rem;
  line-height: 3.2rem;
  @media only screen and (max-device-width : 768px) {
    font-size: 2.6rem;
    line-height: 3.0rem;
  }
}
h3 {
  font-size: 2.4rem;
  line-height: 2.8rem;
  @media only screen and (max-device-width : 768px) {
    font-size: 2.2rem;
    line-height: 2.6rem;
  }
}
h4 {
  font-size: 2.2rem;
  line-height: 2.6rem;
  @media only screen and (max-device-width : 768px) {
    font-size: 2.0rem;
    line-height: 2.4rem;
  }
}
h5 {
  font-size: 2.0rem;
  line-height: 2.4rem;
  @media only screen and (max-device-width : 768px) {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}
h6 {
  font-size: 1.8rem;
  line-height: 2.2rem;
  @media only screen and (max-device-width : 768px) {
    font-size: 1.6rem;
    line-height: 2.0rem;
  }
}

b, strong {
  font-weight: 700;
}

pre {
  display: block;
  font-family: $code-font-family;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.6rem;
  margin: 2.0rem 0 2.0rem 0;
  padding: 2.0rem;
  overflow-x: auto;
  code {
    background-color: inherit;
    color: inherit;
  }
}

code {
  font-family: $code-font-family;
  font-size: 1.6rem;
  font-weight: 400;
  background-color: $alt-bg-color;
  color: $fg-color;
  padding: 0.2rem 0.4rem 0.2rem 0.4rem;
}

blockquote {
  border-left: 2px solid $alt-bg-color;
  padding-left: 2.0rem;
  line-height: 2.2rem;
  font-weight: 400;
  font-style: italic;
}

th, td {
  padding: 1.6rem;
}
table {
  border-collapse: collapse;
}
table td, table th {
  border: 2px solid $alt-fg-color;
}
table tr:first-child th {
  border-top: 0;
}
table tr:last-child td {
  border-bottom: 0;
}
table tr td:first-child,
table tr th:first-child {
  border-left: 0;
}
table tr td:last-child,
table tr th:last-child {
  border-right: 0;
}

img {
  max-width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;

  min-height: 100vh;
  width: 100%;
}

.container {
  margin: 0 auto;
  max-width: 90.0rem;
  width: 100%;
  padding-left: 2.0rem;
  padding-right: 2.0rem;
}

.fab {
  font-weight: 400;
}

.fas {
  font-weight: 700;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.fab {
  font-weight: 400;
}

.fas {
  font-weight: 900;
}
