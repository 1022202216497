// Fonts
$text-font-family: Merriweather, Georgia, serif;
$heading-font-family: Lato, Helvetica, sans-serif;
$code-font-family: 'Source Code Pro', 'Lucida Console', monospace;

// Colors
$bg-color: #FAFAFA !default;
$fg-color: #212121 !default;
$alt-bg-color: #E0E0E0 !default;
$alt-fg-color: #000 !default;
$link-color: #1565c0 !default;
